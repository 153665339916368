import { format } from "date-fns";
import axios from 'axios';
import CryptoJS from "crypto-js";

export default {
  generateToken,

};

// CREATE AUTHORIZE TOKEN
async function generateToken() {
 
  try{
  let result = await axios({
    method: "GET",
    url: process.env.VUE_APP_SERVICE_ADMIN + "/api/get_time",
    headers: {},
  });
  return new Promise ((resolve) => {
    let token;
    let tk;
    var secret = process.env.VUE_APP_SECRET_KEY;
    secret = CryptoJS.enc.Utf8.parse(secret);
    if(result.data.status === 'OK'){
       token = `${result.data.now}_Bearer ${process.env.VUE_APP_BEARER_TOKEN}`;
       tk = window.btoa(token);
      var ciphertext = CryptoJS.AES.encrypt(tk, secret, {mode: CryptoJS.mode.ECB}).toString();
      resolve({ status: "success", msg: "OK", code: tk });
    }else{
      resolve({ status: "error", msg: "ไม่สามารถเรียกAuthorizeได้" , code: tk });
    }
    })
  }catch(err){
    return resolve({ status: "error", msg: err, code: "" });
  }

}