export default {
  setColor
};
function setColor(data) {
  console.log(data);
  let color_code =localStorage.getItem("color_code");
  console.log(color_code);
  let colorGroup = {};
  // if (data === "#5c5c5c") {
  //   //คาร์บอน
  //   colorGroup.alertSort = data;
  //   colorGroup.theme = "#484848";
  //   colorGroup.BG = "#ececec";
  //   colorGroup.chipText = "#FFFFFF";
  //   colorGroup.alertText = "#FFFFFF";
  //   colorGroup.darkTheme = true;
  // } else if (data === "#007cc3") {
  //   // ความกลมกลืน

  //   colorGroup.alertSort = "#99cae7";
  //   colorGroup.theme = data;
  //   colorGroup.BG = "#E8EAF6";
  //   colorGroup.chipText = "#FFFFFF";
  //   colorGroup.alertText = "#FFFFFF";
  //   colorGroup.darkTheme = true;
  // } else if (data === "#6cb6d2") {
  //   //ชายหาด

  //   colorGroup.alertSort = "#a7a194";
  //   colorGroup.theme = data;
  //   colorGroup.BG = "#efe7d4";
  //   colorGroup.chipText = "#FFFFFF";
  //   colorGroup.alertText = "#FFFFFF";
  //   colorGroup.darkTheme = true;
  // } else if (data === "#fff") {
  //   //เปือยเปล่า

  //   colorGroup.alertSort = "#757575";
  //   colorGroup.theme = "#a6a6a6";
  //   colorGroup.BG = "#fff"; //#FAFAFA
  //   colorGroup.chipText = "#FFFFFF";
  //   colorGroup.alertText = "#FFFFFF";
  //   colorGroup.darkTheme = true;
  // } else if (data === "#ede7de") {
  //   //กระดูก

  //   colorGroup.alertSort = "#FCEFC9";
  //   colorGroup.theme = "#a5a19b"; //'#99958f'//'#B9B5AD'
  //   colorGroup.BG = "#ede7de";
  //   colorGroup.chipText = "#FFFFFF";
  //   colorGroup.alertText = "#000000";
  //   colorGroup.darkTheme = true;
  // } else if (data === "#006990") {
  //   //ทะเลสาบ

  //   colorGroup.alertSort = "#006990";
  //   colorGroup.theme = "#005271";
  //   colorGroup.BG = "#E7F8FF";
  //   colorGroup.chipText = "#FFFFFF";
  //   colorGroup.alertText = "#ffffff";
  //   colorGroup.darkTheme = true;
  // } else if (data === "#aba2de") {
  //   //ลาเวนเดอร์

  //   colorGroup.alertSort = data;
  //   colorGroup.theme = "#847faf";
  //   colorGroup.BG = "#f6f4fc";
  //   colorGroup.chipText = "#FFFFFF";
  //   colorGroup.alertText = "#ffffff";
  //   colorGroup.darkTheme = true;
  // } else if (data === "#c2cd23") {
  //   //ตะไคร์

  //   colorGroup.alertSort = data;
  //   colorGroup.theme = "#99a11b";
  //   colorGroup.BG = "#f8fce0";
  //   colorGroup.chipText = "#FFFFFF";
  //   colorGroup.alertText = "#ffffff";
  //   colorGroup.darkTheme = true;
  // } else if (data === "#cbf8ff") {
  //   //โอเอซิส

  //   colorGroup.alertSort = "#FFF1DE";
  //   colorGroup.theme = "#00DDFF";
  //   colorGroup.BG = "#f7fbfc";
  //   colorGroup.chipText = "#FFFFFF";
  //   colorGroup.alertText = "#000000";
  //   colorGroup.darkTheme = true;
  // } else if (data === "#D9541E") {
  //   //รถซิ่ง

  //   colorGroup.alertSort = data; //'#922D10'
  //   colorGroup.theme = "#ab4217";
  //   colorGroup.BG = "#fcf9e3";
  //   colorGroup.chipText = "#FFFFFF";
  //   colorGroup.alertText = "#FFFFFF";
  //   colorGroup.darkTheme = true;
  // } else if (data === "#c2bcab") {
  //   //กรวด

  //   colorGroup.alertSort = "#879122"; //'#922D10'
  //   colorGroup.theme = "#999489";
  //   colorGroup.BG = "#D4D6BA"; //'#f4f3ff'
  //   colorGroup.chipText = "#FFFFFF";
  //   colorGroup.alertText = "#FFFFFF";
  //   colorGroup.darkTheme = true;
  // } else if (data === "#f3edde") {
  //   //ทราย

  //   colorGroup.alertSort = "#F3EDDE"; //'#922D10'
  //   colorGroup.theme = "#A7A194";
  //   colorGroup.BG = "#fcfce0"; //'#f4f3ff'
  //   colorGroup.chipText = "#FFFFFF";
  //   colorGroup.alertText = "#000000";
  //   colorGroup.darkTheme = true;
  // } else if (data === "#0095d3") {
  //   //ความราบลื่น

  //   colorGroup.alertSort = "#0095d3"; //'#922D10'
  //   colorGroup.theme = "#0075A6";
  //   colorGroup.BG = "#DFECF5"; //'#f4f3ff'
  //   colorGroup.chipText = "#FFFFFF";
  //   colorGroup.alertText = "#FFFFFF";
  //   colorGroup.darkTheme = true;
  // } else if (data === "#89cbdf") {
  //   //ท้องฟ้า

  //   colorGroup.alertSort = data; //'#922D10'
  //   colorGroup.theme = "#6CA0B0";
  //   colorGroup.BG = "#E6F1F4"; //'#f4f3ff'
  //   colorGroup.chipText = "#FFFFFF";
  //   colorGroup.alertText = "#FFFFFF";
  //   colorGroup.darkTheme = true;
  // } else if (data === "#a5a5a5") {
  //   //ควันบุหรี่

  //   colorGroup.alertSort = data; //'#922D10'
  //   colorGroup.theme = "#828282";
  //   colorGroup.BG = "#fafafa"; //'#f4f3ff'
  //   colorGroup.chipText = "#FFFFFF";
  //   colorGroup.alertText = "#FFFFFF";
  //   colorGroup.darkTheme = true;
  // } else if (data === "#a1a0a4") {
  //   //เหล็ก

  //   colorGroup.alertSort = data; //'#922D10'
  //   colorGroup.theme = "#7F7E81";
  //   colorGroup.BG = "#e0e0e7"; //'#f4f3ff'
  //   colorGroup.chipText = "#FFFFFF";
  //   colorGroup.alertText = "#FFFFFF";
  //   colorGroup.darkTheme = true;
  // } else if (data === "#6db33f") {
  //   //ต้นไม้

  //   colorGroup.alertSort = data; //'#922D10'
  //   colorGroup.theme = "#416B25";
  //   colorGroup.BG = "#E3EDBD"; //'#f4f3ff'
  //   colorGroup.chipText = "#FFFFFF";
  //   colorGroup.alertText = "#FFFFFF";
  //   colorGroup.darkTheme = true;
  // } else if (data === "#000") {
  //   //ฟ้าสลัว

  //   colorGroup.alertSort = data; //'#922D10'
  //   colorGroup.theme = "#6f1616";
  //   colorGroup.BG = "#EDE1E1"; //'#f4f3ff'
  //   colorGroup.chipText = "#FFFFFF";
  //   colorGroup.alertText = "#FFFFFF";
  //   colorGroup.darkTheme = true;
  // } else if (data === "#003d79") {
    //คลื่น

    // colorGroup.alertSort = data; //'#922D10'
    // colorGroup.theme = "#00305f";
    // colorGroup.BG = "#eaeef2"; //'#f4f3ff'
    // colorGroup.chipText = "#FFFFFF";
    // colorGroup.alertText = "#FFFFFF";
    // colorGroup.darkTheme = true;
  //}
  if (data === "null" || data === "#174966" || data === "primary" || color_code === null) {
    //default
    colorGroup.alertSort = "#64DD17";
    colorGroup.theme = "#174966";
    //#031F6F"
    colorGroup.BG = /*"#FAFAFA"*/ color_code;
    colorGroup.chipText = "#FFFFFF";
    colorGroup.alertText = "#FFFFFF";
    colorGroup.darkTheme = true;
    // localStorage.setItem("color_code", "hsla(202, 63%, 97%, 1)");
  } else {
    // สีอื่นๆ
    colorGroup.alertSort = data;
    colorGroup.theme = data;
    colorGroup.BG = color_code;
    colorGroup.chipText = "#FFFFFF";
    colorGroup.alertText = "#FFFFFF";
    colorGroup.darkTheme = true;
  }
  return colorGroup;
}
