export default {
  onechatplugin
};

function onechatplugin(onechat_token) {
  if (document.getElementById("botChat") !== null) {
    document.getElementById("botChat").remove();
    document.getElementById("minBotChat").remove();
  }
  var div_chatbot = document.createElement("div");
  document.getElementsByTagName("body")[0].appendChild(div_chatbot);
  div_chatbot.outerHTML =
    "<div id='botChat' style='border-top-left-radius: 10px; border-top-right-radius: 10px; position: absolute;margin-top: 0px; margin-right: 0px; margin-bottom: 0px; padding: 0px; border: 0px; background: transparent; overflow: hidden; position: fixed; z-index: 16000004; right: 10px; bottom: 0px;display: none; width: 280px; height: 380px; box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 3px 2px;'><div id='botTitleBar' style='z-index: 16000005;height: 27px; width: 360px; position:fixed; cursor: pointer; border-top-left-radius: 10px; border-top-right-radius: 10px;'></div><iframe frameborder='0' style='background-color: transparent; vertical-align: text-bottom; position: relative; width: 100%; height: 100%; min-width: 100%; min-height: 100%; max-width: 100%; max-height: 100%; margin: 0px; overflow: hidden; display: block;' allow='geolocation' src='https://chat.one.th:8096/login_service/Onebox/Abc0db0f3e1d25da7910159d507fa6fe1bc38313c2a054fc390d27ef37a73b30fad2a1866efec4789b2e901affe130d26?onechat_token=" +
    onechat_token +
    "'></iframe></div><div id='minBotChat' style='border-radius: 10px;margin-top: 0px; margin-right: 0px; margin-bottom: 0px; padding: 0px; border: 0px; background: transparent;position: fixed; z-index: 16000002; width: 180px; height: 30px; right: 10px; bottom: 15px;'><div id='minBotChatTitle' style='z-index: 16000003;height: 30px; width: 180px; position:fixed; cursor: pointer;'></div><iframe frameborder='0' style='background-color: transparent; vertical-align: text-bottom; position: relative; width: 100%; height: 100%; min-width: 100%; min-height: 100%; max-width: 100%; max-height: 100%; margin: 0px; overflow: hidden; display: block;' src='https://chat.one.th:8096/minchat/Onebox/Abc0db0f3e1d25da7910159d507fa6fe1bc38313c2a054fc390d27ef37a73b30fad2a1866efec4789b2e901affe130d26' allow='geolocation'></iframe></div>";

  document.querySelector("body").addEventListener("click", function(e) {
    e.target.matches = e.target.matches || e.target.msMatchesSelector;
    if (e.target.matches("#botTitleBar")) {
      document.getElementById("botChat").style.display = "none";
      document.getElementById("minBotChat").style.display = "block";
    } else if (e.target.matches("#minBotChatTitle")) {
      document.getElementById("botChat").style.display = "block";
      document.getElementById("minBotChat").style.display = "none";
    }
  });
}
