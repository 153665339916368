<template>
  <div id="app">
    <div>
      <loading v-if="$store.state.set_loading" />
      <router-view v-else />
    </div>
  </div>
</template>

<script>
import onechat_script from "@/plugins/onechat";
import loading from "@/views/onebox-loading.vue"
import { mapGetters } from "vuex";
export default {
  components: {loading},
  data: function() {
    return {
      windowWidth: window.innerWidth,
      txt: ""
    };
  },
  computed: {
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
      "dataAccountCCTV"
    ])
  },
  watch: {
    dataAuthorize: function(val) {
      this.fn_onResize();
    },
    windowWidth(newHeight, oldHeight) {
      this.txt = `it changed to ${newHeight} from ${oldHeight}`;
      console.log(this.txt);
    },
    $route: {
      immediate: true,
      handler (value) {
        if (!sessionStorage.getItem("onechat_token")) {
          this.fn_runplugin_onechat();
        }
        // console.log("value", value);
        // console.log("sessionStorage.getItem(onechat_plugin_enable)", sessionStorage.getItem("onechat_plugin_enable"));
      }
    }
  },
  methods: {
    fn_runplugin_onechat() {
      if (sessionStorage.getItem("loginonemail")) {
        if (document.getElementById("botChat") !== null) {
          document.getElementById("botChat").remove();
          document.getElementById("minBotChat").remove();
        }
        sessionStorage.removeItem("onechat_token");
        sessionStorage.removeItem("onechat_plugin_enable");
      } else {
        if (
          process.env.VUE_APP_ENVIRONMENT === "PRODUCTION" &&
          this.dataAuthorize === true &&
          this.$store.state.service === "OneID" &&
          (this.$vuetify.breakpoint.name === "xl" ||
            this.$vuetify.breakpoint.name === "lg" ||
            this.$vuetify.breakpoint.name === "md")
        ) {
          if (
            sessionStorage.getItem("onechat_plugin_enable") !== true ||
            sessionStorage.getItem("onechat_plugin_enable") === null 
          ) {
          console.log(sessionStorage.getItem("onechat_token"));
          if (sessionStorage.getItem("onechat_token") !== null && this.$route.name !== 'home' ) {
            onechat_script.onechatplugin(
              sessionStorage.getItem("onechat_token")
            );
          } else {
            this.axios
              .post(
                "https://chat-api.one.th/manage/api/v1/get_onechat_token",
                { one_id: this.$store.state.one_account_id },
                {
                  headers: {
                    Authorization:
                      "Bearer Abc0db0f3e1d25da7910159d507fa6fe1bc38313c2a054fc390d27ef37a73b30fad2a1866efec4789b2e901affe130d26"
                  }
                }
              )
              .then(res => {
                console.log(res);
                console.log(this.$route.name);
                if(this.$route.name !== 'home' && this.$route.name !== 'publicfile' && this.$route.name !== 'publicfolder'){
                  sessionStorage.setItem("onechat_token", res.data.onechat_token);
                  sessionStorage.setItem("onechat_plugin_enable", true);
                  onechat_script.onechatplugin(
                    sessionStorage.getItem("onechat_token")
                  );
                }
              })
              .catch(err => {
                console.log(err);
              });
          }
          }
        } else {
          if (document.getElementById("botChat") !== null) {
            document.getElementById("botChat").remove();
            document.getElementById("minBotChat").remove();
          }
          sessionStorage.removeItem("onechat_token");
          sessionStorage.removeItem("onechat_plugin_enable");
        }
      }
    },
    fn_onResize() {
      this.windowWidth = window.innerWidth;
      console.log(this.$route.name);
      if (this.dataAuthorize === false || this.windowWidth < 300 || this.$route.name === 'home' ) {
        if (document.getElementById("botChat") !== null) {
          document.getElementById("botChat").remove();
          document.getElementById("minBotChat").remove();
        }
      } else {
        this.fn_runplugin_onechat();
      }
      console.log(this.txt);
    }
  },
  created() {
    // this.fn_runplugin_onechat();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.fn_onResize);
    });
    this.fn_runplugin_onechat();
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.fn_onResize);
  }
};

</script>

<style>
/* @import url("https://fonts.googleapis.com/css?family=Prompt:300,600&display=swap&subset=latin-ext,thai"); */
@import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@400;600&display=swap");

#app {
  color: #2c3e50;
  background-color: white;
  text-rendering: optimizeLegibility;
  font-family: "Sarabun", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body {
  color: #2c3e50;
  background-color: white;
  text-rendering: optimizeLegibility;
  font-family: "Sarabun", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  max-width: 100%;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaaaaa; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888; 
}

.swal2-container {
  font-family: -apple-system, "Sarabun", Helvetica, Arial, sans-serif !important;
}

.theme--dark.v-card > .v-card__text *:not(.v-icon, .darkmode *, .v-input--switch *), 
.theme--dark span:not(.darkmode *, span.v-btn__content),
.theme--dark .v-list-item__title,
.theme--dark .v-tab.v-tab--active,
.theme--dark .v-btn:not(.v-btn--outlined, .darkmode *) {
  color: white !important;
}
.v-navigation-drawer__content {
  overflow-y: scroll !important;
  padding-left: 2px;
}
</style>
