import VueCookies from "vue-cookies";
import CryptoJS from "crypto-js";

export default {
  setValueLogin,
};

// Set value login to cookies and localStorage
function setValueLogin(result) {
  let encodetoken = window.btoa(result.data.result["accesstoken"])
  let user_id_key = (Math.random() + 1).toString(36).substring(7);
  VueCookies.set("user_key", user_id_key);
  VueCookies.set("token", encodetoken);
  VueCookies.set('token_auth', true, 60 * 60 * 24);
  VueCookies.set("accountid", CryptoJS.AES.encrypt(window.btoa(result.data.result.accountid), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
  // localStorage.setItem("thai_email", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["thai_email"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
  VueCookies.set("thai_email", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["thai_email"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
  // localStorage.setItem("user_id", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["user_id"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
  VueCookies.set("user_id", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["user_id"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
  VueCookies.set("username", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["username"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
  //localStorage.setItem("username", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["username"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
}